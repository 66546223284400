import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function deleteUser(user, cnt){
    if(user != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 4,
                    user: user
                }
            })
            
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Удалён пользователь: ' + this.$data.results[cnt].login)
                    this.$data.results.splice(cnt, 1)
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}