import localizeFilter from '@/filter/localize.filter'
export default function GetLastcatalytic(){
    this.$Progress.start()
    axios.get('/dashboard.php', 
        {
            params: 
            {
                type: 5,
            }
        })
        .then(response => {
             if(response.data != -10){
                this.$data.results = response.data;
                this.$data.allresults = response.data;  
            }else{
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            }
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}