import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function unBan(user, cnt, type){
    if(user != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 3,
                    user: user
                }
            })
            .then(response => {
                if(response.data != -10){
                    if(type){
                        Journal(localStorage.getItem('login'), 'Пользователь ' + this.$data.results[cnt].login + ' разблокирован. Статус активации и пароль сброшены.')
                        this.$data.results[cnt].block = '0'
                        this.$data.results[cnt].activation = '0'
                    }else{
                        Journal(localStorage.getItem('login'), 'Пользователь ' + this.$data.results[cnt].login + ' разблокирован. Статус активации и пароль сброшены.')
                        this.$data.results.splice(cnt, 1)
                    }
                    
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}