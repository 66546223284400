<template>
    <div>
        <!-- Modal -->
        <div id="modal-center-one" class="uk-flex-top uk-modal-container" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                <!-- SLIDE SHOW -->
                <div class="uk-position-relative uk-visible-toggle" uk-slideshow>
                    <ul class="uk-slideshow-items">
                        <li v-for="image in modal[10]">
                            <img :src=" noimage + modal[11] +'/'+image" alt=" " uk-cover> <!-- TODO: ОБРАБОТКА ФОТО-->
                        </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" style="color:blue" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" style="color:blue" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
<!--
                    <div class="uk-card-badge uk-label"> {{price([modal[4], modal[5], modal[6], modal[7]]) | tofix(modal[7]) | formatPrice}} / {{price([modal[4], modal[5], modal[6], modal[7]]) * modal[8] / 1000| tofix(modal[7]) | formatPrice}} </div>
-->

                    <div class="uk-hidden@s uk-card-badge uk-label" style="opacity:60%; text-transform:none; font-size:0.78rem"> {{localizeFilter('Kg')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) | tofix(modal[7]) | formatPrice}} </div>
                    <div class="uk-hidden@s uk-card-badge uk-label" style="background-color:#f01e1e; opacity:60%; margin-top:25px; text-transform:none; font-size:0.78rem"> {{localizeFilter('Part')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) * modal[8] / 1000| tofix(modal[7]) | formatPrice}} </div>
                </div>
                <!-- END SLIDE SHOW -->
                <div>
                    <div class="uk-dark uk-card-body">
                        <h2 class="uk-card-title uk-text-bold" style="margin-bottom:0px">  {{this.$data.modal[0]}} {{this.$data.modal[1]}} {{this.$data.modal[2]}} {{this.$data.modal[3]}}</h2>
                        <div class="uk-grid-divider" uk-grid><h4 class=" uk-visible@s uk-text-bold" style="color:blue">{{localizeFilter('Kg')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) | tofix(modal[7]) | formatPrice}}</h4> <h4 class="uk-visible@s uk-text-bold" style="color:red">{{localizeFilter('Part')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) * modal[8] / 1000| tofix(modal[7]) | formatPrice}} </h4></div>
                        <div class="uk-child-width-expand@s" uk-grid style="margin-top:20px">
                            <div>
                                <ul class="uk-list uk-text-medium uk-text-emphasis uk-list-large">
                                    <li class = "uk-hidden@s"> <span class="uk-text-muted">{{"Catalic_code" | localize}}:</span> {{this.$data.modal[16] || '-'}} </li>
                                    <li> <span class="uk-text-muted">{{'Type' | localize}}:</span> {{localizeFilter(this.$data.modal[7])}} </li>
                                    <li> <span class="uk-text-muted">{{'Weight' | localize}}:</span> {{this.$data.modal[8]}} </li>
                                    <li> <span class="uk-text-muted">{{'Country' | localize}}:</span> {{this.$data.modal[9] | localize}} </li>
                                    <li class = "uk-hidden@s"> <span class="uk-text-muted">{{"Comment" | localize}}:</span> {{this.$data.modal[14] || '-'}} </li>
                                    <li class = "uk-hidden@s"> <span class="uk-text-muted">{{"Description" | localize}}:</span> {{this.$data.modal[15] || '-'}} </li>
                                </ul>
                            </div>
                            <div style="margin-top:20px">
                                <ul class="uk-list uk-text-medium uk-text-emphasis uk-list-large">
                                    <li> <span class="uk-text-muted">{{'Pt' | localize}}:</span> {{this.$data.modal[4] || 0}} % </li>
                                    <li> <span class="uk-text-muted">{{'Pd' | localize}}:</span> {{this.$data.modal[5] || 0}} % </li>
                                    <li> <span class="uk-text-muted">{{'Rh' | localize}}:</span> {{this.$data.modal[6] || 0}} % </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="uk-overflow-auto">
            <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;">
                <thead>
                    <tr>
                        <th class="uk-table-shrink">#</th>
                        <th class="">{{"Auto" | localize}}</th>
                        <th class="">{{"Catalytic" | localize}}</th>
                        <th class="">{{"Description" | localize}}</th>
                        <th class="">{{"WeightNoGR" | localize}}</th>
                        <!-- <th class="">{{"Type" | localize}}</th> -->
                        <th class="uk-text-nowrap">PT / PD / RH</th>
                        <th class="uk-text-nowrap">{{"Price" | localize}}</th>
                    </tr>
                </thead>
                <tbody v-for="(result, name) in results"  :id="result.id">
                    <tr>
                        <td> {{name + 1}} </td>
                        <td class="uk-table-link">
                            <a class="uk-text-emphasis" v-on:click="ShowModal([result.mark, result.model, result.generation, result.engine_code, result.pt, result.pd, result.rh, result.type, result.weight, result.country, result.images, result.id, result.photopass, result.tags, result.comment, result.specs, result.catalic_code])">
                                {{result.mark}} {{result.model}} {{result.generation}} {{result.engine_code}}
                            </a>
                        </td>
                        <td>{{result.catalic_code || '-'}}</td>
                        <td>{{result.specs || '-'}}</td>
                        <td>{{result.weight}}</td>
                        <!-- <td>{{localize(result.type)}}</td> -->
                        <td class="uk-text-nowrap">{{result.pt || '-'}} / {{result.pd || '-'}} / {{result.rh || '-'}}</td>
                        <td class="uk-text-nowrap"> {{price([result.pt, result.pd, result.rh, result.type]) | tofix(result.type) | formatPrice}} / {{price([result.pt, result.pd, result.rh, result.type]) * result.weight / 1000 | tofix(result.type) | formatPrice}}</td> <!-- TODO: ФОРМУЛА ДЛЯ ЦЕН-->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import tofix from "@/filter/fix.filter"
import formatPrice from "@/filter/format.filter"
import store from '@/store'
import ShowModal from '@/config/ShowModal.general'
import GetLastcatalytic from '@/config/GetLastCatalytic.dashboard'
import price from '@/config/Price.general'


export default {
    data: () => ({
        search: '',
        results: '',
        modal: '',
        allresults: '',
        images: '',
        //noimage: 'http://autocat.ru/images/',
        // PRODUCTION
        noimage: 'http://autocatcatalog.com/images/',
    }),
    methods:{
        GetLastcatalytic,
        ShowModal,
        localizeFilter,
        price
    },
    beforeMount(){
        this.GetLastcatalytic();
    },
    filters:{
        tofix, formatPrice
    }
}
</script>
