<template>
    <div class="uk-overflow-auto">
        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;">
            <thead>
                <tr>
                    <th class="uk-table-shrink">#</th>
                    <th class="">{{"FIO" | localize}}</th>
                    <th class="">{{"Login" | localize}}</th>
                    <th class="">{{"Role" | localize}}</th>
                    <th class="uk-text-nowrap">{{"Manage" | localize}}</th>
                </tr>
            </thead>
            <tbody v-for="(result, cnt) in results"  :id="result.id">
                <tr>
                    <td> {{cnt + 1}} </td>
                    <td>{{result.FIO || '-'}}</td>
                    <td>{{result.login || '-'}}</td>
                    <td>{{result.role | localize}}</td>
                    <td>
                        <ul class="uk-iconnav">
                            <li><a uk-icon="icon: trash" :uk-tooltip="'title:' + localizeFilter('Delete') +'; pos: bottom'" @click="deleteUser(result.id, cnt)"></a></li>
                            <li><a uk-icon="icon: happy" :uk-tooltip="'title:' + localizeFilter('unBan') +'; pos: bottom'" @click="unBan(result.id, cnt, false)"></a></li>
                        </ul> 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>  
</template>

<script>
import unBan from '@/config/unBan.dashboard'
import deleteUser from '@/config/deleteUser.dashboard'
import localizeFilter from '@/filter/localize.filter'

export default {
    data: () => ({
        results: []
    }),
    beforeMount(){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 2,
                }
            })
            .then(response => {
                    if(response.data != -10){
                    this.$data.results = response.data;
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    },
    methods:{
        localizeFilter,
        unBan,
        deleteUser

    }
}
</script>