<template>
    <div class="uk-grid uk-grid-medium" data-uk-grid>
        <!-- panel -->
        <div class="uk-width-1-1">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-expand"><h4>{{"LastInsertCatalic" | localize}}</h4></div>
                    </div>
                </div>
                <div class="uk-card-body uk-padding-remove-top">
                    <div class="card-container uk-overflow-auto">
                        <LastCatalytic/>
                    </div>
                </div>
            </div>
        </div>
        <!-- /panel -->
         <!-- panel -->
        <div class="uk-width-1-2@l uk-margin-medium-bottom">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-expand"><h4>{{"BlockedUsers" | localize}}</h4></div>
                    </div>
                </div>
                <div class="uk-card-body uk-padding-remove-top">
                    <div class="chart-container">
                       <BlockedUsers/>
                    </div>
                </div>
            </div>
        </div>
        <!-- panel -->
        <div class="uk-width-1-2@l uk-margin-medium-bottom">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-expand"><h4>{{"Rates" | localize}}</h4></div>
                    </div>
                </div>
                <div class="uk-card-body uk-padding-remove-top">
                    <div class="chart-container">
                       <chart :height="200"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- /panel -->
    </div>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import BlockedUsers from '@/components/dashboard/BlockedUsers'
import LastCatalytic from '@/components/dashboard/LastCatalytic'
import chart from '@/components/dashboard/Chart'


export default {
    metaInfo:{
        title: localizeFilter('TitleDashboardMain')
    },
    data: () => ({
        password: '',
        login: '',
        key: ''
    }),
    components:{
        BlockedUsers, LastCatalytic, chart
    }
}
</script>