import localizeFilter from '@/filter/localize.filter'
export default function getChartData(){
        return axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 20
                }
            })
            .then(response => {
                if(response.data != -10){
                    this.dates = response.data[0].reverse()
                    this.pts = response.data[1].reverse()
                    this.pds = response.data[2].reverse()
                    this.rhs = response.data[3].reverse()
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            })
}