<script>
import { Line } from 'vue-chartjs'
import getChartData from '@/config/Chart.dashboard'

export default {
    extends: Line,
    data:() => ({
        dates:'',
        pts:'',
        pds:'',
        rhs:''
    }),
    methods:{
        getChartData
    },

    mounted(){        
        this.getChartData().then(() => {
            this.gradient1 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
            this.gradient1.addColorStop(0, 'rgba(255, 204, 0, 0.5)')
            this.gradient1.addColorStop(0.5, 'rgba(255, 204, 0, 0.25)');
            this.gradient1.addColorStop(1, 'rgba(255, 204, 0, 0)');
            this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
            this.gradient2.addColorStop(0, 'rgba(0, 255, 51, 0.5)')
            this.gradient2.addColorStop(0.5, 'rgba(0, 255, 51, 0.25)');
            this.gradient2.addColorStop(1, 'rgba(0, 255, 51, 0)');
            this.gradient3 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
            this.gradient3.addColorStop(0, 'rgba(51, 0, 255, 0.5)')
            this.gradient3.addColorStop(0.5, 'rgba(51, 0, 255, 0.25)');
            this.gradient3.addColorStop(1, 'rgba(51, 0, 255, 0)');
            this.renderChart({
                    labels: this.dates,
                    datasets: [
                        {
                            label: 'Pt',
                            data: this.pts,
                            borderColor: '#FFCC33',
                            pointBackgroundColor: 'white',
                            backgroundColor: this.gradient1,
                            // по умолчанию точки прозрачные, что не всегда красиво
                            pointRadius: 4,
                        },
                        {
                            label: 'Pd',
                            data: this.pds,
                            borderColor: '#66FF33',
                            pointBackgroundColor: 'white',
                            backgroundColor: this.gradient2,
                            // по умолчанию точки прозрачные, что не всегда красиво
                            pointRadius: 4,
                        },
                        {
                        label: 'Rh',
                        data: this.rhs,
                        borderColor: '#366FF',
                        pointBackgroundColor: 'white',
                        backgroundColor: this.gradient3,
                        // по умолчанию точки прозрачные, что не всегда красиво
                        pointRadius: 4,
                    }
                ]},

                {
                    maintainAspectRatio:false
                }
            )
        }) 

    }
}
</script>